<template>
  <footer v-if="hideFooter" class="bg-gray-50 mt-20 rounded-lg shadow dark:bg-gray-900">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
      <div class="sm:flex sm:items-center sm:justify-between">

        <NuxtLink
          :to="{ name: user ? 'home' : 'index' }"
          class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
        >
          <NuxtImg
            src="/img/logoipsum.svg"
            alt="notion tools logo"
            class="w-20"
          />
        </NuxtLink>

        <ul
          class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400"
        >

          <li v-for="(item, idx) in footerNavs" :key="idx">
            <NuxtLink
              :to="{ name: item?.href}"
              class="me-4 md:me-6 text-gray-500 hover:text-gray-600 dark:hover:text-gray-400"
            >
              {{ item?.name }}
            </NuxtLink>
          </li>

        </ul>
      </div>

      <hr
        class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"
      />
      
      <span class="text-md text-gray-500 text-center block"
        >© Copyright {{ currYear }}. All Rights Reserved.</span
      >
    </div>
  </footer>

</template>

<script>
import { computed } from "vue"
import opnformConfig from "~/opnform.config.js"

export default {
  setup() {
    const authStore = useAuthStore()
    return {
      user: computed(() => authStore.user),
      opnformConfig,
    }
  },

  data: () => ({
    currYear: new Date().getFullYear(),
    footerNavs: [
      { href: "privacy-policy", name: "Privacy Policy" },
      { href: "terms-conditions", name: "Terms and Conditions" },
    ],
  }),

  computed: {
    //Hide footer when route is /forms/..
    hideFooter() {
      return !this.$route.path.includes("/forms/")
    },
  },
}
</script>
